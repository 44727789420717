<template>
  <v-container>
    <v-layout row>
      <v-flex xs10>
        <v-text-field
          ref="piecesToPackInput"
          v-model="piecesToPackInput"
          @update:searchInput="_updatePiecesTextField"
          @keyup.enter="_updatePiecesToPack"
          @keyup.space="_updatePiecesToPack"
          label="Peça para empacotar"
        ></v-text-field>

        <div class="text-xs-center">
          <v-chip
            close
            selected
            v-for="piece in piecesToPack"
            :v-model="piece"
            :key="piece"
            color="primary"
            text-color="white"
            @input="_removePiece(piece)"
            >{{ piece }}</v-chip
          >
        </div>
      </v-flex>
      <v-flex xs2>
        <v-btn
          class="mt-3"
          :disabled="piecesToPack.length === 0"
          color="primary"
          small
          :loading="loading"
          @click="_packVolume"
        >
          Criar Pacote
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    externalPiecesToPack: { type: Array },
    pieces: { type: Array },
    syncPiecesToPack: { type: Function }
  },
  data: () => ({
    piecesToPackInput: '',
    loading: false,
    piecesToPack: []
  }),

  mounted() {
    const piecesToPackInput = this.$refs.piecesToPackInput;
    piecesToPackInput.focus();
  },
  methods: {
    ...mapActions({
      _listPieces: 'listPieces',
      _createVolume: 'createVolume'
    }),
    _updatePiecesTextField(input) {
      if (input) {
        this.piecesToPackInput = input;
      }
    },
    async _updatePiecesToPack(e) {
      this.piecesToPack = this.piecesToPack || [];
      const filtered = this.pieces.filter(
        (piece) =>
          piece.pieceId.toUpperCase() === this.piecesToPackInput.toUpperCase()
      );
      if (
        filtered.length > 0 &&
        !this.piecesToPack.includes(this.piecesToPackInput.toUpperCase())
      ) {
        this.piecesToPack.push(this.piecesToPackInput.toUpperCase());
        this.piecesToPackInput = '';
        this.syncPiecesToPack(this.piecesToPack);
      }
    },
    async _packVolume() {
      try {
        this.loading = true;
        await this._createVolume({
          projectId: this.$route.params.id,
          pieces: this.piecesToPack
        });
        await this._listPieces(this.$route.params.id);
        this.piecesToPack = [];
      } catch (e) {
        console.log(e);
        this.snackbarText =
          'Ocorreu um erro tentando criar o volume. Tente novamente.';
        this.showSnackbar = true;
      }
      this.loading = false;
    },
    _removePiece(piece) {
      this.piecesToPack.splice(this.piecesToPack.indexOf(piece), 1);
      this.syncPiecesToPack(this.piecesToPack);
    }
  },
  watch: {
    externalPiecesToPack(newValue) {
      this.piecesToPack = newValue;
    }
  }
};
</script>
